import React from 'react';
import Layout from '@components/layout';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { useMedia } from '@src/lib/hooks';
import LanaContainer from './lanaContainer';

import * as styles from './nekad.module.css';
import Hero from '../../components/hero';
import Contact from '../../components/contact';

export default function ApplicationDeniedPage({ data }) {
  const pageContent =
    data.contentfulPage.pageContent[0].bodyText.childMarkdownRemark.html;
  const title = data.contentfulPage.headLine;
  const isMobile = useMedia(['(max-width: 768px)'], [true], false);
  return (
    <Layout path="/lana/nekad" newStyle>
      <Hero title={title} breadcrumbs={[{ name: 'Hem', path: '/' }]} />
      <LanaContainer padded={isMobile}>
        <div
          className={styles.loan_refused}
          dangerouslySetInnerHTML={{ __html: pageContent }}
        ></div>
      </LanaContainer>
      <Contact hideFAQ />
    </Layout>
  );
}

ApplicationDeniedPage.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query ApplicationDeniedPageQuery {
    contentfulPage(pageUrl: { eq: "/lana/nekad" }) {
      pageContent {
        ... on ContentfulDescriptionComponent {
          id
          bodyText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      headLine
    }
  }
`;
